window.convertToDutchFloat = function (val) {
  return roundToCents(val, 2).toFixed(2).replace(".", ",");
};

window.parseDutchFloat = function (val) {
  const parsedVal = parseFloat(val.replace(",", "."));
  if (isNaN(parsedVal)) return 0;
  return parsedVal;
};

window.roundToCents = function (value, precision) {
  if (precision == null) precision = 2;

  return Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision);
};

window.convertToDecimal = function (value, precision) {
  if (precision == null) precision = 2;
  return parseInt(Math.round((value * Math.pow(10, precision + 1)) / 10));
};

window.convertDecimalBackToFloat = function (value, precision) {
  if (precision == null) precision = 2;
  return value / Math.pow(10, precision);
};

window.displayEuros = function (price) {
  return (
    "€ " +
    roundToCents(price)
      .toFixed(2)
      .replace(".", ",")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  );
};
