class TouchScaler {
  constructor() {
    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.calcDistance = this.calcDistance.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
    this.bind = this.bind.bind(this);
    this.scaling = false;
    this.listeners = { scale: [], scaleStart: [] };
    document.addEventListener('touchstart', this.handleTouchStart);
  }

  handleTouchStart(e) {
    if (e.touches.length === 2) {
      this.scaling = true;
      this.beginLength = this.calcDistance(e);
      document.addEventListener('touchmove', this.handleTouchMove);
      document.addEventListener('touchend', this.handleTouchEnd);
      return Array.from(this.listeners.scaleStart).map((f) =>
        f.call(this));
    }
  }

  calcDistance(e) {
    const dX = Math.abs(e.touches[0].clientX - e.touches[1].clientX);
    const dY = Math.abs(e.touches[0].clientY - e.touches[1].clientY);
    return Math.sqrt(Math.pow(dX, 2) + Math.pow(dY, 2));
  }

  handleTouchMove(e) {
    if (e.touches.length === 2) {
      const dist = this.calcDistance(e);
      const scale = dist / this.beginLength;

      return Array.from(this.listeners.scale).map((f) =>
        f.call(this, scale));
    }
  }

  handleTouchEnd(e) {
    document.removeEventListener('touchmove', this.handleTouchMove);
    return document.removeEventListener('touchend', this.handleTouchEnd);
  }

  bind(event, func) {
    return this.listeners[event].push(func);
  }
}


window.initInvoiceScaling = function () {
  if (window.invoiceScalingInitialized) {
    return;
  }
  $(window).resize(scaleInvoice);

  let startScale = 0;

  window.touchScaler = new TouchScaler();
  window.touchScaler.bind('scaleStart', () => startScale = window.currentInvoiceScale);

  window.touchScaler.bind('scale', function (scale) {
    let newScale = scale * startScale;
    newScale = Math.max(window.minInvoiceScale, newScale);
    window.scaleInvoiceByGesture = true;
    return window.setInvoiceTransform(newScale);
  });

  return window.invoiceScalingInitialized = true;
};

window.scaleInvoice = function () {
  if (window.scaleInvoiceByGesture || $('.invoice_pages').length == 0) {
    return;
  }

  if ($('.invoice_pages').outerWidth() > $('.page-content').width()) {
    const scale = $('.page-content').width() / $('.invoice_pages').outerWidth();
    window.minInvoiceScale = scale;
    return window.setInvoiceTransform(scale);
  } else {
    window.setInvoiceTransform(1);
    return $('.invoice_pages').css('-webkit-transform', 'scale(' + 1 + ')');
  }
};

window.setInvoiceTransform = function (scale) {
  window.currentInvoiceScale = scale;
  const transformStr = 'scale(' + scale + ')';
  $('.invoice_pages').css('-webkit-transform', transformStr);
  $('.invoice_pages').css('-moz-transform', transformStr);
  $('.invoice_pages').css('-ms-transform', transformStr);
  $('.invoice_pages').css('transform', transformStr);

  const transformOriginStr = '0% 0%';
  $('.invoice_pages').css('-webkit-transform-origin', transformOriginStr);
  $('.invoice_pages').css('-moz-transform-origin', transformOriginStr);
  $('.invoice_pages').css('-ms-transform-origin', transformOriginStr);
  return $('.invoice_pages').css('transform-origin', transformOriginStr);
};


$(document).bind('turbolinks:load  modal:load', function () {
  window.initInvoiceScaling();
  scaleInvoice();
});
