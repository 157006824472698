import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  initialize() {
    this.update = this.update.bind(this)
  }

  connect() {
    this.inputTarget.addEventListener("input", this.update)
  }

  disconnect() {
    this.inputTarget.removeEventListener("input", this.update)
  }

  update() {
    if (this.count < this.minLength && this.count > 0) {
      this.element.classList.add('has-error')

      this.output.innerHTML = 'is te kort: voeg '+ (this.minLength - this.count.toString()) + ' tekens toe.'
    }
    else {
      this.output.innerHTML = ''
    }
  }

  get output() {
    let span

    if (this.element.querySelector('.help-block')) {
      span = this.element.querySelector('.help-block')
    }
    else {
      span = document.createElement('span')
      span.classList.add('help-block')
      this.element.appendChild(span)
    }

    return span
  }

  get element() {
    return this.inputTarget.parentElement.parentElement
  }

  get count() {
    return this.inputTarget.value.length
  }

  get minLength() {
    return this.inputTarget.minLength
  }
}
