
$(document).on('turbolinks:request-start', function () {
  $('body').addClass('loading');
  $('.modal-body').addClass('loading');
  return NProgress.start();
});

$(document).on('turbolinks:load', function () {
  $('body').removeClass('loading');
  $('.modal-body').addClass('loading');
  return NProgress.done();
});

$(document).on("ajaxSend", () => NProgress.start());
$(document).on("ajaxSuccess", () => NProgress.done());
$(document).on("ajaxError", () => NProgress.done());
