let openTrengoWidget = function(e) {
  e.preventDefault();
  Trengo.eventBus.$emit('widget.open', {
    intro_text: this.getGreetingMessage,
    channel: 'chat'
  });
};

let handleTrengoLink = function(e) {
  let location = new URL(e.target);

  // load support articles through ajax
  if (location.pathname.indexOf('/support') != -1) {
    $.rails.ajax({ url: location.pathname, dataType: 'script' });
    if (window.inApp()) {
      window.Trengo.Api.Widget.close();
    }
    e.preventDefault();
  } else {
    if (window.inApp()) {
      window.postMessageToApp({
        method: 'openlink',
        data: {
          url: location.href
        }
      });
      e.preventDefault();
    }
  }
}

let loadTrengoScript = function() {
  (function(d, script, t) {
    script = d.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://static.widget.trengo.eu/embed.js';
    d.getElementsByTagName('head')[0].appendChild(script);
  })(document);
};

window.initTrengoChat = function() {
  if (!document.getElementById('trengo-web-widget')) {
    window.Trengo = window.Trengo || {};
    window.Trengo.key = 'ohUIWIGBCUPwvfn';
    window.Trengo.on_ready = function() {
      let trengoFrameDocument = $('.TrengoWidgetPanel__iframe iframe')[0]
        .contentDocument;
      $(trengoFrameDocument).on('click', 'a.linkified', handleTrengoLink);
      $('.trengo_mobile_chat').on('click', openTrengoWidget);
    };

    loadTrengoScript();
  }
};
