import posthog from 'posthog-js'

window.posthog = posthog;

// Temporary wrapper until we enable PostHog for all pages
window.posthogCapture = function(eventName, properties = {}) {
  if (window.posthogInitialized) {
    window.posthog.capture(eventName, properties);
  }
}

// Redact company name from URLs and paths
// Example
// http://localhost:3000/ondernemingen/shalva-usubov/inkomsten/"
// will be redacted to
// http://localhost:3000/ondernemingen/company_name_redacted/inkomsten/
function redactCompanyName(urlOrPath) {
  const pattern = /\/ondernemingen\/[^\/]+/;
  return urlOrPath.replace(pattern, '/ondernemingen/company_name_redacted');
}

// Recursively redact company name from object properties
// PostHog event properties are nested objects with complex structure
function preprocessProperties(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = redactCompanyName(obj[key]);
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      preprocessProperties(obj[key]); // Recursively process nested objects
    }
  }
}

window.posthogEventPreprocessor = preprocessProperties;
