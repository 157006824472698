
import 'raty-js/lib/jquery.raty.js';

import star_on from 'raty-js/lib/images/star-on.png';
import star_off from 'raty-js/lib/images/star-off.png';
import star_half from 'raty-js/lib/images/star-half.png';

$.fn.raty.defaults.starOff = star_off;
$.fn.raty.defaults.starOn = star_on;
$.fn.raty.defaults.starHalf = star_half;

$(document).bind('turbolinks:load', function () {
  $('.rating').each(function(index, el) {
    $(el).raty({ score: $(el).data('value'), readOnly: true })
  });
});