class LoginForm {
  constructor(formEl) {
    this.el = $(formEl);
    if (this.el.data('login-form-initialized'))
      return;

    this.submitButton = this.el.find('input[type=submit]');
    this.emailInput = this.el.find('input[name="user[email]"]');
    this.passwordInput = this.el.find('input[name="user[password]"]');

    this.emailInput.on('input change', this.onChange.bind(this));
    this.passwordInput.on('input change', this.onChange.bind(this));

    this.onChange();
    this.el.data('login-form-initialized', true)
  }
  onChange() {
    if (this.emailInput.val() != '' && this.passwordInput.val() != '') {
      this.submitButton.removeAttr("disabled")
    } else {
      this.submitButton.attr("disabled", "disabled")
    }
  }
}

export default LoginForm;
