(function () {
  // The `ct` param specifies which element must contain the created textarea,
  // use this when copying from a modal.
  function copyToClipboard(str, ct = document.body) {
    var el = document.createElement('textarea');    // Create a <textarea> element
    el.value = str;                                 // Set its value to the string that you want copied
    el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
    el.style.position = 'absolute';
    el.style.left = '-9999px';                      // Move outside the screen to make it invisible
    ct.appendChild(el);                             // Append the <textarea> element to the HTML document
    var selected =
      document.getSelection().rangeCount > 0        // Check if there is any content selected previously
        ? document.getSelection().getRangeAt(0)     // Store selection if found
        : false;                                    // Mark as false to know no selection existed before
    el.select();                                    // Select the <textarea> content
    document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
    ct.removeChild(el);                             // Remove the <textarea> element
    if (selected) {                                 // If a selection existed before copying
      document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
      document.getSelection().addRange(selected);   // Restore the original selection
    }
  };

  window.initClipboard = function () {
    $(document).on('click', '[data-copy-to-clipboard]', function (e) {
      e.preventDefault();
      var $this = $(this),
        content = $this.data('copy-to-clipboard'),
        container = $this.closest('.modal')[0] || document.body;
      copyToClipboard(content, container);
      window.showFlashMessage('success', 'Naar plakbord gekopieerd');
    });
  }

  $(document).on('turbolinks:load modal:load', function () {
    window.initClipboard();
  });



})();
