// Purpose: Stimulus controller to send events to PostHog.
// Usage: 
// Add `data-controller="posthog"` to an element and `posthog_event_name_value="event_name"` and `posthog_properties_value="{key: value}"`.
// to specify the event name and properties.

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    eventName: String,
    properties: { type: Object, default: {} }
  }

  connect() {
    this.element.addEventListener("click", this.sendEvent.bind(this))
  }

  disconnect() {
    this.element.removeEventListener("click", this.sendEvent.bind(this))
  }

  sendEvent() {
    const eventName = this.eventNameValue
    const properties = this.propertiesValue || {}

    if (eventName) {
      window.posthogCapture(eventName, properties)
    }
  }
}
