window.initForms = function () {
  $('form').keydown(function (event) {
    if ((event.ctrlKey || event.metaKey) && (event.keyCode === 13)) {
      $(this).trigger('submit');
    }
  });
};


$(document).on('turbolinks:load modal:load', function () {
  window.initForms();
});