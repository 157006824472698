class ExternalLinksFix {
  constructor() {
    this.parseExternalLinks = this.parseExternalLinks.bind(this);
    const {
      userAgent
    } = window.navigator;
    // if userAgent.match(/RompslompApp/)
    this.parseExternalLinks();
  }


  parseExternalLinks() {
    const currentLocation = this.getLocation(window.location);

    const links = $("a:not([data-linking-url])");
    for (let link of Array.from(links)) {
      const location = this.getLocation(link.href);

      if ((location.host !== null) && (location.host !== '') && (location.host !== currentLocation.host)) {
        $(link).attr('data-linking-url', 'true');
      }
    }

    return $("a[data-linking-url]").attr('target', '_blank');
  }

  getLocation(url) {
    const l = document.createElement("a");
    l.href = url;
    return l;
  }
}


$(document).on('turbolinks:load modal:load', function () {
  new ExternalLinksFix();
});
