class AutoCompleteSuggestions {
  constructor(form, formInputs, autocompleteUrl) {
    this.form = $(form);
    this.formInputs = formInputs;
    this.autocompleteUrl = autocompleteUrl;
    this.initHandlers();
  }

  initHandlers() {
    this.formInputs.forEach(
      function (input) {
        $(this.form[0][input]).keyup(this.findSuggestions.bind(this));
      }.bind(this)
    );
  }

  findSuggestions(e) {
    clearTimeout(this.handleFindSuggestionsTimeout);
    this.handleFindSuggestionsTimeout = setTimeout(
      this.handleFindSuggestions.bind(this),
      700
    );
  }

  handleFindSuggestions() {
    let data = this.form.serialize();
    $.ajax({
      url: this.autocompleteUrl,
      data: data,
      method: 'POST',
    });
  }
}

export default AutoCompleteSuggestions;
