(function($) {
  // Dispatch jQuery events as regular DOM events.
  //
  // Delegated events are given a new name in the format `jquery:<original event name>`.
  // If you delegate `ajax:send` you will be able to listen for `jquery:ajax:send`
  // on native event listeners such as Stimulus actions and `EventTarget.addEventListener`.
  //
  // Notes:
  //   * The original jQuery event can be accessed as through `event.detail.jqueryEvent`.
  //   * The jQuery event parameters can be accessed as through `event.detail.jqueryEventParams`.
  //
  // Example:
  //   delegate 'ajax:send'
  //   document.addEventListener 'jquery:ajax:send', (event) -> console.log(event.detail)
  //
  // Code based on https://gist.github.com/kaspermeyer/7fe28bb7c55c2810e7b5f3d5e67c1a44.
  //
  function delegate(eventName) {
    function handler(event, ...params) {
      const delegatedEvent = new CustomEvent(`jquery:${eventName}`, {
        bubbles: true,
        cancelable: true,
        detail: {
          jqueryEvent: event,
          jqueryEventParams: params
        }
      })

      event.target.dispatchEvent(delegatedEvent)
    }

    $(document).on(eventName, handler)
  }

  delegate('invoice-line:added')
  delegate('invoice-line:product-added')
  delegate('invoice-line:removed')
  delegate('invoice:lines-reordered')
  delegate('changeDate')
  delegate('modal_success_client')
})(jQuery)
