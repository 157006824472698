export default class EventDispatcher {
  // callbacks: {}

  constructor() {
    this.bind = this.bind.bind(this);
    this.unbind = this.unbind.bind(this);
    this.trigger = this.trigger.bind(this);
  }

  bind(event_name, callback) {
    if (!this.callbacks) { this.callbacks = {}; }
    if (!this.callbacks[event_name]) { this.callbacks[event_name] = []; }
    this.callbacks[event_name].push(callback);
    return this;
  }

  unbind(event_name, callback) {
    if (!this.callbacks) { this.callbacks = {}; }
    if (!this.callbacks[event_name]) { this.callbacks[event_name] = []; }
    if (callback === null) {
      this.callbacks[event_name] = [];
    } else {
      this.callbacks[event_name] = (Array.from(this.callbacks[event_name]).filter((x) => x !== callback));
    }
    return this;
  }

  trigger(event_name, data) {
    if (!this.callbacks) { this.callbacks = {}; }
    if (!this.callbacks[event_name]) { this.callbacks[event_name] = []; }
    const chain = this.callbacks[event_name];
    if (chain != null) { for (let callback of Array.from(chain)) { callback(data); } }
    return this;
  }
};


