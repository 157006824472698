
const setUserAgent = function (window, userAgent) {
  // Works on Firefox, Chrome, Opera and IE9+
  if (navigator.__defineGetter__) {
    navigator.__defineGetter__('userAgent', () => userAgent);
  } else if (Object.defineProperty) {
    Object.defineProperty(navigator, 'userAgent', {
      get() {
        return userAgent;
      }
    }
    );
  }

  // Works on Safari
  if (window.navigator.userAgent !== userAgent) {
    const userAgentProp = {
      get() {
        return userAgent;
      }
    };
    try {
      Object.defineProperty(window.navigator, 'userAgent', userAgentProp);
    } catch (e) {
      window.navigator = Object.create(navigator, { userAgent: userAgentProp });
    }
  }
};

// Generate file name if not present in Data object
const fileNameFromData = (data) => {
  if (data.originalFilename) {
    return data.originalFilename
  }

  // Backwards compatibility
  let filename = 'Upload ' + moment(new Date()).format('l LTS');

  if (data.mimetype) {
    if (data.mimetype.indexOf('png') !== -1) {
      filename += '.png';
    } else if ((data.mimetype.indexOf('jpg') !== -1) || (data.mimetype.indexOf('jpeg') !== -1)) {
      filename += '.jpg';
    } else if (data.mimetype.indexOf('pdf') !== -1) {
      filename += '.pdf';
    }
  }

  return filename;
}

window.postMessageToApp = function (message) {
  message = JSON.stringify(message);
  if (window.ReactNativeWebView) {
    return window.ReactNativeWebView.postMessage(message);
  } else {
    return window.postMessage(message);
  }
};


window.initApp = function () {

  $(document).on('click', 'body[data-app-major-version] [data-download-url]', function (e) {

    e.preventDefault();
    window.postMessageToApp({
      method: 'download',
      data: {
        url: $(e.target).data('download-url'),
        mimetype: $(e.target).data('download-mimetype'),
        filename: $(e.target).data('download-filename'),
      }
    });

    return false;
  });

  // When a user clicks on a upload button the android app handles this in a custom way
  $(document).on('click', 'body.app_android[data-app-major-version] input[type=file][data-url]', function (e) {
    e.preventDefault();
    window.lastClickedFileUpload = $(e.target);
    return window.postMessageToApp({
      method: 'upload',
      url: $(e.target).data('url')
    });
  });

  document.addEventListener("message", function (e) {
    const message = JSON.parse(e.data);
    if (message.method === 'upload') {
      if (message.data.partIndex === 0) {
        window.currentUpload = [];
      }

      window.currentUpload.push(message.data.imageBase64);

      if (message.data.partIndex === (message.data.totalParts - 1)) {
        const imageBase64 = window.currentUpload.join('');
        let blob = window.b64toBlob(imageBase64, message.data.mimetype);

        const filename = fileNameFromData(message.data);

        window.lastClickedFileUpload.fileupload('send', { files: { name: filename }, blob });
        blob = null;
        window.currentUpload = null;
        return delete window.currentUpload;
      }
    }
  });

  return $(document).on('click', 'body[data-app-major-version] [data-linking-url]', function (e) {
    e.preventDefault();

    let url = $(e.currentTarget).data('linkingUrl');

    if ((url === 'true') || (url === true)) {
      url = $(e.currentTarget).attr('href');
    }

    return window.postMessageToApp({
      method: 'openlink',
      data: {
        url
      }
    });
  });
};

window.inApp = function() {
  return $(window.document.body).attr('data-app-major-version') != null;
}

window.initApp();


window.b64toBlob = function (b64Data, contentType, sliceSize) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  let offset = 0;
  while (offset < byteCharacters.length) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    let i = 0;
    while (i < slice.length) {
      byteNumbers[i] = slice.charCodeAt(i);
      i++;
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
    offset += sliceSize;
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};
