class HeaderFix {
  constructor() {
    this.isInput = this.isInput.bind(this);
    this.handleOnInputFocusIn = this.handleOnInputFocusIn.bind(this);
    this.handleOnInputFocusOut = this.handleOnInputFocusOut.bind(this);
    this.handleOnTouchMove = this.handleOnTouchMove.bind(this);
    this.setNavbarPositionAbsolute = this.setNavbarPositionAbsolute.bind(this);
    this.setNavbarPositionFixed = this.setNavbarPositionFixed.bind(this);
    const {
      userAgent
    } = window.navigator;

    if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/RompslompAppIos/)) {
      this.addEventListeners();
    }
  }


  addEventListeners() {
    $(document).bind("focus", this.handleOnInputFocusIn);
    $(document).bind("focusin", this.handleOnInputFocusIn);
    return $(document).bind("focusout", this.handleOnInputFocusOut);
  }

  isInput(e) {
    return $(e.target).prop('nodeName') === 'INPUT';
  }

  handleOnInputFocusIn(e) {
    if (!this.isInput(e)) {
      return;
    }
    clearInterval(this.interval);

    this.setNavbarPositionAbsolute();
    this.interval = setInterval(this.handleOnTouchMove, 10);
    $(document).bind('touchmove', this.handleOnTouchMove);
    return $(document).bind('scroll', this.handleOnTouchMove);
  }

  handleOnInputFocusOut(e) {
    if (!this.isInput(e)) {
      return;
    }

    clearInterval(this.interval);
    $(document).unbind('touchmove', this.handleOnTouchMove);
    $(document).unbind('scroll', this.handleOnTouchMove);

    return this.setNavbarPositionFixed();
  }


  handleOnTouchMove() {
    return this.setNavbarPositionAbsolute();
  }


  setNavbarPositionAbsolute() {

    $('.navbar').css('position', 'absolute');
    return $('.navbar').css('top', window.scrollY);
  }

  setNavbarPositionFixed() {
    $('.navbar').css('position', 'fixed');
    return $('.navbar').css('top', '0');
  }
}

new HeaderFix();
