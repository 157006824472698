
// This function checks if the input fields of the dropdown have focus or are filled in
const contentHasFocusOrFilledIn = function (e) {
  let hasChanged = false;
  const dropdown = $(e.target).closest('.dropdown');

  $('input[type=text], input[type=password]', dropdown).each((index, el) => hasChanged = hasChanged || ($(el).val() !== ""));

  const hasFocus = $('input', e.target).is(':focus');
  return hasFocus || hasChanged;
};

// This function checks if the dropdown is shown on mobile
const isInsideCollapsedMenuBar = e => $(e.target).closest('.collapse, .collapsing').attr('aria-expanded') === 'true';

window.initDropdowns = function () {
  const dropdown_selector = 'ul.nav li.dropdown, ul.nav li.dropdown ul.dropdown-menu';
  return $(dropdown_selector).each(function (index, element) {
    if ($(element).data('dropdown-initialized')) { return; }
    $(element).click(function (e) {
      if (isInsideCollapsedMenuBar(e) || $(e.target).hasClass('clickable')) { return; }
      const selected_item = $(e.target).closest('li');
      // selected_item = $(e.currentTarget)
      const is_login_dropdown = $(e.target).parents().hasClass('login-dropdown');
      const is_clickable = selected_item.hasClass('clickable');
      if (is_clickable) {
        const href = selected_item.find('a').attr('href');
        Turbolinks.visit(href);
        return true;
      }
      // console.log selected_item
      if (!is_login_dropdown && selected_item.hasClass('dropdown') && selected_item.hasClass('open')) {
        e.stopPropagation();
        e.preventDefault();
      }
      return true;
    });

    $(element).hover(function (e) {

      if (isInsideCollapsedMenuBar(e)) { return; }
      const listItem = $(e.target).parents('li.dropdown');
      const wasClosedBefore = !listItem.hasClass('open');
      if (wasClosedBefore) {
        listItem.trigger('open');
      }

      const other = $('li.open');
      other.each(function (i, e) {
        if ($(e).hasClass('open') && ($(e)[0] !== listItem[0])) {
          return $(e).trigger('close');
        }
      });

      // # other.trigger('close')
      other.removeClass('open');
      other.find('ul.dropdown-menu').hide();

      if (listItem[0])
        clearInterval(listItem[0].timeout);

      listItem.addClass('open');
      listItem.find('ul.dropdown-menu').show();


      return true;
    }
      , function (e) {
        if (contentHasFocusOrFilledIn(e)) { return; }
        if (isInsideCollapsedMenuBar(e)) { return; }

        const listItem = $(e.target).parents('li.dropdown');
        clearInterval(listItem[0].timeout);
        clearInterval(listItem[0].showTimeout);
        return listItem[0].timeout = setTimeout(function () {
          const wasOpenBefore = listItem.hasClass('open');
          if (wasOpenBefore) {
            listItem.trigger('close');
          }

          listItem.removeClass('open');
          return listItem.find('ul.dropdown-menu').hide();
        }
          , 800);
      });
    return $(element).data('dropdown-initialized', true);
  });
};


$(document).bind('turbolinks:load  modal:load', function () {
  window.initDropdowns();
})