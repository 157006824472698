import browserUpdate from "browser-update";

document.addEventListener('DOMContentLoaded', () => {
    // this will show only on the browser that we include here in required
    // for more information please check the docs https://browser-update.org/
    //e = IE, f = firefox, o = opera, s = safari, c = chrome
    const userAgent = navigator.userAgent;

    if (userAgent && userAgent.startsWith('RompslompApp')) {
        return;
    }

    browserUpdate({
        required: { e: -2, f: -3, o: -3, s: -1, c: -3, edge: -3, samsung: -3 },
        insecure: true,
        unsupported: true,
        reminder: 24,
        reminderClosed: 7,
        text: "Je browser ({brow_name}) is verouderd. Tijd voor een update!",
        // test: true, // this is for development mode,
        l: 'nl',
        onshow: function(infos) {
            const customIgnoreButton = document.createElement('button');
            customIgnoreButton.textContent = 'Sluiten';
            customIgnoreButton.className = 'browser-update-ignore-button';

            // Close the notification when clicked
            customIgnoreButton.onclick = function() {
                const notificationElement = document.querySelector('.buorg');
                if (notificationElement) {
                    notificationElement.style.display = 'none'; // Hide the notification
                }
            };

            // Append the custom button to the notification element
            const notificationElement = document.querySelector('.buorg');
            if (notificationElement) {
                notificationElement.appendChild(customIgnoreButton);
            }
        }
    });
});