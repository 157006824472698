import SignaturePad from 'signature_pad';

var ratio = Math.max(window.devicePixelRatio || 1, 1);

function resize(canvas) {
  canvas.width = canvas.offsetWidth * ratio;
  canvas.height = canvas.offsetHeight * ratio;
  canvas.getContext("2d").scale(ratio, ratio);
}

/**
 * Initializes a signature pad input element. Intended to be used in conjunction with the +SignatureInput+ input
 # element.
  *
  * **Examples**
  *
  *  $('#my-pad').signaturePad();
  *  $('#my-pad').signaturePad({ dataURL: someBase64EncodedPNG });
  *
  *  $('#my-pad').on('signature:updated', function(event, dataURL, signaturePad){
  *    console.log(event, dataURL, signaturePad);
  *  });
  */
$.fn.signaturePad = function (options) {
  options = options || {};

  return this.each(function () {
    var $this = $(this);

    if ($this.data('signature-pad')) {
      console.warn('signaturePad already initialized for:', $this);
      return;
    }

    var canvas = $this.find('canvas'),
      input = $this.find('input:hidden'),
      clearBtn = $this.find('button'),
      signaturePad = new SignaturePad(canvas[0]);

    function getDataURL() {
      return signaturePad.isEmpty() ? '' : signaturePad.toDataURL();
    }

    function setDataURL(dataURL) {
      signaturePad.fromDataURL(dataURL);
      input.val(dataURL);
    }

    signaturePad.onEnd = function () {
      $this.trigger('signature:updated', [getDataURL(), signaturePad]);
    };

    resize(canvas[0]);

    if ('dataURL' in options) {
      setDataURL(options.dataURL);
    } else {
      setDataURL(input.val());
    }

    $this.on('signature:updated', function (e, dataURL) {
      input.val(dataURL);
    });

    function clear() {
      var wasEmpty = signaturePad.isEmpty();

      signaturePad.clear();

      $this.trigger('signature:cleared', signaturePad);

      if (!wasEmpty) {
        $this.trigger('signature:updated', ['', signaturePad]);
      };
    }

    clearBtn.on('click', function (e) {
      e.preventDefault();
      clear();
    });

    $(window).on('resize', function () {
      resize(canvas[0]);
      clear();
    });

    $this.data('signature-pad', signaturePad);

    $this.trigger('signature:initialized', [getDataURL(), signaturePad, setDataURL])
  });
}


window.initSignaturePad = function (ct) {
  ct = ct || document;
  $('.signature-pad', ct).signaturePad();
}

$(document).on('turbolinks:load shown.bs.modal', function (e) {
  window.initSignaturePad(e.target);
  return console.info;
});
