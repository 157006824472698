
$(function () {
  $.rails.allowAction = function (link) {
    if (!link.attr('data-confirm')) { return true; }
    $.rails.showConfirmDialog(link);
    return false;
  };

  $.rails.confirmed = function (link) {
    var event = $.Event('confirm:complete');
    link.trigger(event, [true]);

    if (!event.isDefaultPrevented()) {
      link.removeAttr('data-confirm');
      link.attr('data-method', link.attr('data-method') || 'get');
      link.trigger('click.rails');
    }
  };

  $.rails.cancelled = function (link) {
    return link.trigger('confirm:complete', [false]);
  };

  $.rails.showConfirmDialog = function (link) {
    const message = link.data('confirm');
    const html = `\
<h1>${link.data('confirm-title') || 'Zeker weten?'}</h1>
<p>${message}</p>\
`;
    const footer = `\
<a data-dismiss="modal" class="btn btn-default cancel ${link.data('cancel-btn-class')}">${link.data('cancel') || 'Annuleren'}</a>
<a data-dismiss="modal" class="btn btn-default confirm ${link.data('ok-btn-class')}">${link.data('ok') || 'Doorgaan'}</a>\
`;

    window.modal.open({ body: html, name: 'confirm', footer });
    //
    //
    // html = """
    //        <div class="modal" id="confirmationDialog">
    //          <div class="modal-dialog">
    //            <div class="modal-content">
    //              <div class="modal-header">
    //                <a class="close" data-dismiss="modal">×</a>
    //                <h1>Weet je het zeker?</h1>
    //                <p>#{message}</p>
    //              </div>
    //              <div class="modal-footer">
    //                <a data-dismiss="modal" class="btn">#{link.data('cancel') || "annuleer"}</a>
    //                <a data-dismiss="modal" class="btn btn-primary confirm">#{link.data('ok') || "Ok"}</a>
    //              </div>
    //            </div>
    //          </div>
    //        </div>
    //        """
    // $(html).modal()
    var onChoose = function (e) {
      $(document).off('click', '.modal-footer a', onChoose);

      if($(e.target).hasClass('confirm')) {
        return $.rails.confirmed(link);
      } else {
        return $.rails.cancelled(link);
      }
    };

    $(document).on('click', '.modal-footer a', onChoose);

    return window.modal.modal_close('confirm', () => $(document).off('click', '.modal-footer a', onChoose));
  };



  return $.rails.showAlert = function (message, options) {
    // message = link.attr 'data-confirm'
    const html = `\
<h1>${options.title}</h1>
<p>${message}</p>\
`;
    const footer = `\

<a data-dismiss="modal" class="btn btn-default confirm">${"OK"}</a>\
`;

    window.modal.open({ body: html, name: 'confirm', footer });

    var onOk = function () {
      $(document).off('click', '.modal-footer .confirm', onOk);
      return window.modal.close({ name: 'cofirm' });
    };


    $(document).on('click', '.modal-footer .confirm', onOk);

    return window.modal.modal_close('confirm', () => $(document).off('click', '.modal-footer .confirm', onOk));
  };
});
