
window.Slideshow = class Slideshow {
  constructor(el) {
    this.el = el;


    this.amtSlides = $('.slide', this.el).length;
    this.buildCarrousel();
    this.setActiveItem(0);
    this.start();
  }

  buildCarrousel() {
    $('.carousel-indicators').text('');
    return (() => {
      const result = [];
      for (let i = 0, end = this.amtSlides - 1, asc = 0 <= end; asc ? i <= end : i >= end; asc ? i++ : i--) {
        var li = $('<li>');
        (i => {
          li.mouseover(() => {
            this.setActiveItem(i);
            return this.stop();
          });
          return li.mouseleave(() => {
            // this.setActiveItem(i)
            return this.start();
          });
        })(i);
        result.push($('.carousel-indicators').append(li));
      }
      return result;
    })();
  }

  setActiveItem(i) {
    clearTimeout(this.opacityTimeout);
    $('.carousel-indicators li').removeClass('active');
    $($('.carousel-indicators li').get(i)).addClass('active');
    this.currentSlide = i;
    const windowWidth = $('.window', this.el).width();

    $('.slide', this.el).addClass('hidden-animated');
    $('.slide', this.el).removeClass('visible-animated');
    $($('.slide', this.el).get(i)).addClass('visible-animated');
    return $($('.slide', this.el).get(i)).removeClass('hidden-animated');
  }

  stop() {
    return clearInterval(this.interval);
  }

  start() {
    this.stop();
    return this.interval = setInterval(() => {
      return this.showNextSlide();
    }
      , 8000);
  }

  showNextSlide() {
    this.nextSlide = (this.currentSlide + 1) % (this.amtSlides);
    $('.slide', this.el).removeClass('visible-animated');
    $('.slide', this.el).addClass('hidden-animated');
    return setTimeout(() => {
      return this.setActiveItem(this.nextSlide);
    }
      , 500);
  }
};
