import Plyr from 'plyr';
import Hls from 'hls.js/dist/hls.light.js';

const initializeLoopVideos = function () {
  const loopVideos = $('.loop-video');

  loopVideos.each((i, el) => {
    window['loopVideo' + i] = new Plyr(el, {
      autoplay: true,
      controls: [],
      muted: true,
      clickToPlay: false,
      loop: { active: true },
    });

    window['loopVideo' + i].play();

    return Plyr.setup(window['loopVideo' + i]);
  });
};

$(window).bind('turbolinks:load', function () {
  initializeLoopVideos();
});

const getVideoStream = function(video) {
  const videoType = video.data('videotype');

  if (videoType === 'frontpage') {
    return 'https://rompslomp-public.s3.eu-central-1.amazonaws.com/video/1080p.m3u8';
  } else {
    console.log('No stream provided');
  }
}

const initializeHlsPlayer = function () {
  const hlsVideo = $('#hls-player');
  const stream = getVideoStream(hlsVideo);

  if (stream) {
    new Plyr(hlsVideo[0], { captions: { active: true } });

    if (Hls.isSupported()) {
      const hls = new Hls({ autoStartLoad: false });
      hls.loadSource(stream);
      hls.attachMedia(hlsVideo[0]);
      hlsVideo[0].addEventListener('play', () => hls.startLoad(), {
        once: true,
      });
    } else if (hlsVideo[0].canPlayType('application/vnd.apple.mpegurl')) {
      hlsVideo[0].src = stream;
      hlsVideo[0].addEventListener('loadedmetadata', () => hlsVideo[0].play());
    }
  }
  return Plyr.setup(hlsVideo[0]);
};

window.startHlsPlayer = function () {
  $('#hls-player').removeClass('hidden');
  $('.hls-player-place-holder').hide();
  initializeHlsPlayer();
};


$(window).bind('turbolinks:load', function () {
  $('.hls-player-place-holder').click(window.startHlsPlayer);
});
