/**
 * Attaches an event handler for page changes. It is detached once the handler is executed.
 * @returns {function} Function which detaches the event handler.
 */
window.onPageChange = function(func) {
  const onPageChangeHandler = function(e) {
    unbind();
    func(e);
  };

  const unbind = function() {
    $(document).unbind('turbolinks:before-visit', onPageChangeHandler);
  }

  $(document).bind('turbolinks:before-visit', onPageChangeHandler);

  return unbind;
};

/**
 * Attaches an event handler for the first page load. It is detached once the handler is executed.
 * @returns {function} Function which detaches the event handler.
 */
window.onLoadOnce = function(func) {
  const onLoadHandler = function() {
    unbind();
    func();
  };

  const unbind = function() {
    $(document).unbind('turbolinks:load', onLoadHandler);
  }

  $(document).bind('turbolinks:load', onLoadHandler);

  return unbind;
};
