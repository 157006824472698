$(document).on('modal:load', function () {
  $(window).trigger('pageshow.rails');
});

window.initDataDisableWith = function () {
  $('.btn:not([onclick]):not([data-disable-disable-with]):not([data-wysihtml5-command]):not([disabled=disabled])').each(function () {
    var valueBefore;

    if ($(this).is('a')) {
      valueBefore = $(this).data('ujs:enable-with') || $(this).html();
    } else if ($(this).is('button') || $(this).is('input')) {
      valueBefore = $(this).data('ujs:enable-with') || $(this).val();
    }

    if (valueBefore) {
      // Hack for IE and Edge because div with block elements inside won't react to click event
      if ((window.BrowserTest.isIE || window.BrowserTest.isEdge) && !$(this).data('method') && $(this).attr('href') != '#' && $(this).attr('href') != null) {
        $(this).data('method', 'get');
      }
      $(this).attr('data-disable-with', valueBefore + '...');
      $(this).attr('data-ujs:enable-with', valueBefore);
    }
  })
}
