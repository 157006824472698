class Modal {
  constructor() {
    this.close_all_modals = this.close_all_modals.bind(this);
    this.id = '#myModal';
    this.amt_open_modals = 0;
  }

  createModal(options) {
    if (options == null) { options = {}; }
    const modal = $('<div>').addClass('modal').addClass('fade');

    if (options.name) {
      modal.addClass('modal-' + options.name);
    }
    modal.attr('role', 'dialog');
    modal.attr('aria-hidden', true);
    modal.attr('tabindex', '-1');
    const modal_dialog = $('<div>').addClass('modal-dialog');
    modal.append(modal_dialog);
    const modal_content = $('<div>').addClass('modal-content');
    modal_dialog.append(modal_content);
    const modal_header = $('<div>').addClass('modal-header');
    modal_header.append($('<button>').addClass('close').text('×').attr('data-dismiss', 'modal').attr('type', 'button'));
    modal_header.append($('<h4>').addClass('modal-title'));
    modal_content.append(modal_header);
    modal_content.append($('<div>').addClass('modal-body'));

    modal_content.append($('<div>').addClass('modal-footer'));
    $(document.body).append(modal);

    this.hideModal = () => {


      this.amt_open_modals -= 1;
      const event_name = 'modal_close_' + this.modal_key(options);

      $.event.trigger(event_name, '');
      $.event.trigger('modal_close');
      $(document).unbind(event_name, this.func);

      this.modals[this.modal_key(options)] = null;
      modal.off('hidden.bs.modal', this.hideModal);
      modal.remove();
      if (this.amt_open_modals <= 0) {
        $("body").removeClass("modal-open");
        $("body").removeClass("modal-open-ios");
        return $('.modal-backdrop').fadeOut();
      } else {
        $("body").addClass("modal-open");
        const iOS = /iPhone/.test(navigator.userAgent);
        if (iOS) { return $("body").addClass("modal-open-ios"); }
      }
    };

    modal.on('hidden.bs.modal', this.hideModal);
    return modal;
  }

  modal_key(options) {
    if (options == null) { options = {}; }
    return options.name || 'main';
  }

  find_modal(options) {
    if (options == null) { options = {}; }
    if (this.modals) {
      return this.modals[this.modal_key(options)];
    }
  }

  find_or_create_modal(options) {

    let modal;
    if (options == null) { options = {}; }
    if (this.modals) {
      modal = this.modals[this.modal_key(options)];
    }
    if (modal) {
      return modal;
    }
    this.amt_open_modals += 1;
    modal = this.createModal(options);
    if (!this.modals) { this.modals = {}; }
    this.modals[this.modal_key(options)] = modal;

    modal.attr('nonce', $("meta[name='csp-nonce']").prop('content'));

    return modal;
  }

  open(options) {
    if (options == null) { options = {}; }
    $.event.trigger('before_modal_open');
    const modal = this.find_or_create_modal(options);
    modal.find('.modal-title').html(options.title);

    const body = $(options.body);

    modal.find('.modal-body').html(body);

    const h1 = modal.find('.modal-body').find('h1');
    modal.find('.modal-title').html(h1.html());

    let footer = modal.find('.modal-body').find('.form-footer');
    footer = footer.length ? footer : null;

    if (options.footer) {
      footer = $(options.footer);
    }

    if (footer) {
      footer.find('[data-form-submit],[type=submit]').click(function (e) {
        $(e.target).closest('.modal').find('form').submit();
        $.rails.disableFormElement($(e.currentTarget));
        e.preventDefault();
        return false;
      });

      modal.find('.modal-footer').removeClass('hidden').html(footer);
    } else {
      modal.find('.modal-footer').addClass('hidden');
    }

    h1.detach();

    $('.toc a').on('click', function(e) {
      e.preventDefault();
      document.getElementById(e.target.hash.slice(1))
        .scrollIntoView({ behavior: 'smooth', block: 'start' });
    });

    $(document).trigger('modal:load');

    modal.data('url', options.url);

    modal.modal({
      backdrop: 'static'
    });

    const iOS = /iPhone/.test(navigator.userAgent);
    if (iOS) { $("body").addClass("modal-open-ios"); }
    return $.event.trigger('modal_open');
  }


  close(options) {
    if (options == null) { options = {}; }
    const modal = this.find_modal(options);
    if (!modal) {
      return;
    }
    modal.modal('toggle');
    const event_name = 'modal_close_' + this.modal_key(options);
    delete this.modals[modal.name];
    $.event.trigger(event_name);
    $.event.trigger('modal_close');
    return $(document).unbind(event_name, this.func);
  }

  close_all_modals() {
    return (() => {
      const result = [];
      for (let k in this.modals) {

        const v = this.modals[k];
        result.push(this.close({ name: k }));
      }
      return result;
    })();
  }

  modal_success(name, func, options) {
    if (options == null) { options = {}; }
    const event_name = 'modal_success_' + this.modal_key({ name });
    var f = function (e, response) {
      func(e, response);

      if(options.unbindOnSuccess != false) {
        console.info("unbinding this event", options)
        return $(document).unbind(event_name, f);
      }

    };

    return $(document).on(event_name, f);
  }

  modal_succeeded(args) {
    const modal = this.find_modal(args.options);
    modal.modal('toggle');
    const event_name = 'modal_success_' + this.modal_key(args.options);
    $.event.trigger(event_name, args.result);

    // return $(document).unbind(event_name, this.func);
  }

  modal_close(name, func, options) {
    if (options == null) { options = {}; }
    const event_name = 'modal_close_' + this.modal_key({ name });
    var f = function (e, response) {
      func(e, response);
      return $(document).unbind(event_name, f);
    };
    return $(document).bind(event_name, f);
  }
}

window.modal = new Modal();

$(document).bind('turbolinks:before-visit', function() {
  window.modal = new Modal();
})
