import Turbolinks from 'turbolinks';

Turbolinks.reloadPage = function () {
  Turbolinks.visit(window.location)
}


Turbolinks.start();

document.addEventListener("turbolinks:request-start", function (event) {
  var xhr = event.data.xhr;
  xhr.setRequestHeader("X-Turbolinks-Nonce", $("meta[name='csp-nonce']").prop('content'));
});
